import { UnitOfMeasureConversion } from "@msdyn365-commerce/retail-proxy";

export function getMostRelevantConversion(conversions: UnitOfMeasureConversion[]): UnitOfMeasureConversion | undefined {
    // Accepted units, sorted by priority
    const acceptedToUnits = [
        'stk',
        'Stykk',
        'Stk-BS',
        'Kilogram',
        'kg',
        'Liter',
        '100_meter'
    ];

    for (const unit of acceptedToUnits) {
        const find = conversions?.find(conversion => conversion.ToUnitOfMeasureSymbol === unit);
        if (find) {
            return find;
        }
    }

    return undefined;
}

export function getKilogramConversion(defaultUnitOfMeasure: string | undefined, conversions: UnitOfMeasureConversion[]): UnitOfMeasureConversion | undefined {
    if (!defaultUnitOfMeasure) {
        return undefined;
    }

    const acceptedToUnits = [
        'Kilogram',
        'kg'
    ];
    return conversions?.find(conversion =>
        conversion.FromUnitOfMeasureSymbol === defaultUnitOfMeasure &&
        conversion.ToUnitOfMeasureSymbol && acceptedToUnits.includes(conversion.ToUnitOfMeasureSymbol));
}

export function getPcsConversion(conversions: UnitOfMeasureConversion[]): UnitOfMeasureConversion | undefined {
    const acceptedFromUnits = [
        'Krt-M1',
        'Krt-BS'
    ];
    const acceptedToUnits = [
        'stk',
        'Stykk',
        'Stk-BS',
        'Pose-BS'
    ];

    return conversions?.find(conversion =>
        (conversion.FromUnitOfMeasureSymbol && acceptedFromUnits.includes(conversion.FromUnitOfMeasureSymbol)) &&
        (conversion.ToUnitOfMeasureSymbol && acceptedToUnits.includes(conversion.ToUnitOfMeasureSymbol)));
}

export function getRoundedFactor(conversion: UnitOfMeasureConversion): number {
    // Only round if the conversion is kg/Kilogram
    if (conversion.Factor && (conversion.ToUnitOfMeasureSymbol === 'kg' || conversion.ToUnitOfMeasureSymbol === 'Kilogram')) {
        return Math.round((conversion.Factor + Number.EPSILON) * 100) / 100;
    } else {
        return conversion.Factor || 0;
    }
}

export function getMinimumDecimals(unitOfMeasure: string | undefined): number {
    const units = [
        'kg',
        'Kilogram'
    ];
    return units.includes(unitOfMeasure || '') ? 2 : 0;
}
