import * as React from 'react';
import classnames from 'classnames';

interface ISwitchProps {
    className?: string;
    label?: string;
    checked?: boolean;
    onChange?: (value: boolean) => void;
    mini?: boolean;
    miniLabel?: string;
}

const Switch: React.FC<ISwitchProps> = ({
    className,
    label,
    checked,
    onChange,
    mini,
    miniLabel
}) => {
    const [internalChecked, setInternalChecked] = React.useState(Boolean(checked));

    React.useEffect(() => {
        setInternalChecked(Boolean(checked));
    }, [checked]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalChecked(event.target.checked);
        onChange?.(event.target.checked);
    };

    return (
        <label className={classnames('dlvry-switch', { 'mini': mini }, className)} title={label}>
            <input type='checkbox' checked={internalChecked} onChange={onChangeHandler} />
            <span className='dlvry-switch__slider round' data-mini-label={miniLabel} />
            <span className='dlvry-switch__label'>{!mini && label}</span>
        </label>
    );
};

export default Switch;
